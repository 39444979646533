// Grid helper showing grid overlay
.container--overlay-grid {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    margin: 0 auto;
    z-index: 9999999;
    pointer-events: none;

    [class*="col"] {
        .inner {
            background-color: rgba(#f00, 0.2);
            width: 100%;
            height: 100vh;
        }
    }
}

;@import "sass-embedded-legacy-load-done:1807";