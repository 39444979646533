// default icon style
svg {
    fill: currentcolor;
    width: 100%;
}

.icon {
    display: inline-flex;
    height: 100%;

    svg {
        height: 100%;
    }
}

.icon--return {
    transform: scaleX(-1);
}

// loop trough thema colors
@each $name, $color in $theme-colors {
    .icon--#{$name} {
        color: $color;
    }
}

// Styling for svg sprite
#svg-sprite {
    visibility: hidden;
    max-height: 0;
    z-index: -100;
    overflow: hidden;
    position: absolute;
}

;@import "sass-embedded-legacy-load-done:1818";