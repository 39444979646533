.usp {
    background: $white;
    padding: spacer(10) spacer(8);
    border: 1px solid $bg-secondary;
    border-radius: $border-radius-sm;
    display: flex;
    flex-direction: column;
    gap: spacer(7);
    height: 100%;

    .usp__icon {
        color: white;
        padding: spacer(3);
        width: spacer(10);
        height: spacer(12);
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: px-to-rem(14);
        clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
    }

    .usp__text {
        font-family: $headings-font-family;
        font-weight: $font-weight-light;
        font-size: px-to-rem(15);
        color: $color-base;
        
        strong {
            color: $headings-color;
            font-weight: $headings-font-weight;
            font-size: px-to-rem(16);
        }
    }

    .usp__buttons {
        margin-top: auto;
    }

    &--highlight {
        .usp__text {
            font-family: $font-family-base;

            strong {
                color: $color-base;
                font-weight: $font-weight-bold;
                font-size: px-to-rem(16);
            }
        }
    }
}

;@import "sass-embedded-legacy-load-done:1830";