.cta {
    position: relative;
}

.cta__image, .single-image-clipped {
    height: 218px;
    clip-path: url("#imageClipPath");

    @include media-breakpoint-up(md){
        height: 449px;
    }

    &--card {
        clip-path: unset;
    }

    & > * {
        min-height: 100%;

        @include media-breakpoint-down(md){
            position: absolute;
            width: calc(100vw - 2.5rem);
            left: 50%;
            transform: translateX(-50%);
            height: inherit;
            min-height: unset;
        }
    }

    img {
        border-radius: $border-radius;
    }
}

.cta__card {
    border-radius: $border-radius;
    padding: spacer(12) spacer(10) spacer(6) spacer(10);
    background: $white;
    margin-top: -20%;
    z-index: 1;
    position: relative;

    @include media-breakpoint-up(md){
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
    }
}
;@import "sass-embedded-legacy-load-done:1852";