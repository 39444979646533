// Card component

.card {
    display: flex;
    flex-direction: column;
    flex: 1;
    position: relative;
    background: $white;
    min-height: 100%;
    transition: all 350ms ease-in;
    width: 100%;
    border: 1px solid $bg-primary;
    border-radius: $border-radius;
    overflow: hidden;
    margin-top: 0;
    padding: 0;
    min-width: unset;
    max-width: unset;
}

.card__container {
    display: flex;
    flex-direction: column;
    background-color: $white;
    width: 100%;
}

.card__body,
.card__footer {
    padding: spacer(6) spacer(8);
}

.card__body {
    flex: 1;
}

.card__header {
    position: relative;
}

.card__title {
    font-size: $h6-font-size;
}

.card__image {
    height: 128px;
    width: 100%;
    object-fit: cover;
    max-width: unset;
}

.card__content {
    overflow-wrap: break-word;
    flex: 1;
}

.card__footer {
}

// Bade in cart
.card__badge {
    position: absolute;
    top: spacer(2);
    left: spacer(2);
    margin: 0;
    max-width: calc(100% - #{$spacer});
    overflow: hidden;
    text-overflow: ellipsis;
}

// Bordered card
.card--bordered {
    border: $card-border-width solid $card-border-color;

    .card-body,
    .card-footer {
        padding: $card-spacer-y;
    }
}

// Horizontal card
.card-horizontal-img {
    flex: 0 0 auto;
    width: auto;
}

// Responsive horizontal cards
@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

        .card#{$infix}-horizontal {
            flex-direction: row;
        }
    }
}


.card-project__labels {
    z-index: 1;
    display: flex;
    flex-wrap: wrap;
    gap: spacer(5);
    padding: 0 spacer(3);
}

.card-label {
    @extend .tag;
    
    position: relative;
}

;@import "sass-embedded-legacy-load-done:437";