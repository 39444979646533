// Card component


.project-wrapper-grid {
    display: flex;
    flex-wrap: wrap;
    gap: $container-padding-x;
    align-items: flex-start;

    & > * {
        flex: 1 0 250px;

        @include media-breakpoint-up(lg){
            flex: 0 1 30%;
        }
    }
}

.card-project {
    position: relative;
    display: grid;
    border-radius: $border-radius;
    overflow: hidden;


    &:nth-child(4n + 1) {
        min-height: 455px;
    }
    
    /* 2nd and 5th items */
    &:nth-child(4n + 2) {
        min-height: 514px;
    }
    
    /* 3rd and 9th items */
    &:nth-child(4n + 3) {
        min-height: 495px;
    }

    @include media-breakpoint-up(lg){
        min-height: 455px;
    }


    @include media-breakpoint-up(xl){
        &:nth-child(3n+6){
            margin-top: -20px;
        }

        &:nth-child(4n+4){
            margin-top: -60px;
        }
    }
    
    .card__header {
        min-height: 100%;

        picture {
            &.card__image {
                height: 100%;
            }
        }

        img {
            &.card__image {
                height: 100%;
            }
        }


    }

    & > * {
        grid-column: 1;
        grid-row: 1;
    }

    .card__body {
        display: flex;
        flex-direction: column;
        z-index: 1;

        // height: fit-content;
        margin-top: auto;
        padding: spacer(5);
    }
}


;@import "sass-embedded-legacy-load-done:440";