.editor {
    strong {
        font-weight: $font-weight-bold;
    }

    p {
        margin-bottom: 0;

        & + p {
            margin: initial;
        }
    }

    & > * {
        word-break: break-word;
    }
}

.editor--hero {
    & > * {
        color: $white;
    }
    
    @extend .h4;

    font-weight: $font-weight-bold;
}

.editor--cta {
    font-size: px-to-rem(18);
    color: $color-base;

    & * {
        color: inherit;
    }
}

.editor--shape {
    // z-index: 0;
    
    &:after {
        content: "";
        height: 120px;
        width: calc(100% + 1px);
        background: inherit;
        position: absolute;
        top: -1px;
        bottom: -1px;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
        clip-path: polygon(100% 0, 0 50%, 0 100%, 100% 100%);
        z-index: -1;
    }
    
    &--reverse {
        &:after {
            clip-path: polygon(0 0, 100% 50%, 100% 100%, 0% 100%);
        }
    }

    @include media-breakpoint-up(lg){
        &:after {
            content: "";
            height: 100%;
            width: 250px;
            background: inherit;
            position: absolute;
            top: -1px;
            bottom: -1px;
            left: 00%;
            transform: translateX(-50%);
            clip-path: polygon(0 0, 100% 0, 100% 100%, 50% 100%);
            z-index: -1;
        }

        &--reverse {
            &:after {
                clip-path: polygon(100% 0, 0 0, 0 100%, 50% 100%);
            }
        }
    }
}

;@import "sass-embedded-legacy-load-done:462";