select {
  @extend .form-select;
  @extend .form-control;

  &:focus {
      box-shadow: unset;
      border-color:  $quinary !important;
  }
}

;@import "sass-embedded-legacy-load-done:466";