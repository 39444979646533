
// Submenu
.submenu {
    width: 100%;
    text-decoration: none;
    list-style-type: none;
    max-height: 0;
    transition: max-height 1400ms ease-in;
    overflow: hidden;
    padding: spacer(1) spacer(2);

    &--active {
        opacity: 1;
        max-height: 999px;
    }

    @include media-breakpoint-up(lg){
        position: absolute;
        height: initial !important;
        background: #fafafa;
        top: 100%;
        max-height: unset;
        transform: translateY(-20px);
        opacity: 0;
        transition: all 350ms ease-in;
        overflow: unset;
        block-size: fit-content;
        width: fit-content;
        pointer-events: none;
        min-width: 100%;
        left: 0;
    }

    // Level 2 submenu 
    @include media-breakpoint-up(lg){
        & * > .submenu {
            top: 0;
            left: 100%;
        }
    }
}

// Menu item modifier
.menu__item--dropdown {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    z-index: $zindex-dropdown;

    @include media-breakpoint-up(lg){
        justify-content: initial;
        flex-wrap: nowrap;
    }

    // Dropdown
    @include media-breakpoint-up(lg){
        &:hover {
            & > .submenu {
                transform: translateY(0);
                opacity: 1;
                pointer-events: initial;
            }
        }
    }
}

// Submenu modifier for dropodown
.submenu--dropdown {
    padding: 0;

    .menu__item--dropdown {
        padding: 0;

        &.is-active {
            & > .menu__link {
                color: $secondary;
            }
        }

        .icon--dropdown {
            transform: rotate(90deg);
        }

        &:hover {
            .icon--dropdown {
                transform: unset;
            }
        }
    }

    /* stylelint-disable */
    .menu__link {
        padding: spacer(4);

        &:hover {
            color: $secondary;
        }
    }
    /* stylelint-enable */
}

;@import "sass-embedded-legacy-load-done:454";