.breadcrumbs {
    padding: spacer(1) 0;
    font-size: px-to-rem(13);

    +.hero, 
    +.pane.bg--white {
        margin-top: spacer(4);
    }
}

.breadcrumbs__nav {
    display: flex;
    align-items: center;

    @extend .list;

    margin: 0;
}

.breadcrumbs__seperator {
    margin: 0 spacer(2);
}

.breadcrumbs__link {
    text-decoration: none;
}

.breadcrumb_last {
    opacity: 0.75;
}

;@import "sass-embedded-legacy-load-done:1840";