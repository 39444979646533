.swiper--posts {
    padding: spacer(6); // Add if you use box-shadow
    padding-right: spacer(4);
    padding-left: spacer(4);

    .swiper-wrapper {
        align-items: stretch;
    }

    .swiper__slide {
        height: auto;
    }
}

;@import "sass-embedded-legacy-load-done:1839";