%label {
    width: 100%;
    display: block;
    margin-bottom: $form-label-margin-bottom;
    
    @include font-size($form-label-font-size);
    
    font-weight: $font-weight-medium !important;
    color: $senary;
    font-family: $headings-font-family;
}

label,
.gfield_label {
    @extend %label;
}

input + label {
    color: rgba(#202020, 0.4);
    font-family: $font-family-base;
}

input:checked + label {
    color: #202020;
    font-weight: $font-weight-medium;
}

.label {
    background: rgba($senary, 0.07);
    font-size: px-to-rem(12);
    color: $senary;
    padding: spacer(2) spacer(3);
}

.label-container {
    margin-bottom: spacer(2);
}

.form-container {
    padding: spacer(4);
    overflow: hidden;
    background-color: $white;

    @include media-breakpoint-up(md) {
        padding: spacer(13);
    }

    &--shadow {
        box-shadow: 0 30px 45px rgba($black, 0.05);
    }
}

.gform_wrapper.gravity-theme .gform_fields {
    gap: spacer(6) !important;
}

.gform_heading {
    margin-bottom: spacer(10) !important;
}


.gf_progressbar_wrapper {
    margin-bottom: spacer(2);
}

.gform-page-footer {
    margin-top: spacer(2);
}

.gform-theme-button {
    @extend .btn;
    @extend .btn--secondary;
}

;@import "sass-embedded-legacy-load-done:467";