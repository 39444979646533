.video {
    
}

.video-image {
  aspect-ratio: 16 / 9;

  @include media-breakpoint-up(lg) {
    aspect-ratio: 12 / 5;
  }
  
  width: 100%;
  object-fit: cover;
}

.video__container {
  display: grid;
  place-items: center;
  position: relative;

  & > * {
    grid-column: 1;
    grid-row: 1;
  }
}

;@import "sass-embedded-legacy-load-done:1824";