.person-cta {
  display: flex;
  height: 100%;

  @include media-breakpoint-down(md){
    flex-direction: column-reverse;
  }
}

.person-cta__content {
  color: $white;
  border-radius: 4px;
  padding: min(px-to-rem(55), 15%) min(px-to-rem(48), 15%) min(px-to-rem(40), 15%) min(px-to-rem(40), 15%);

  @include media-breakpoint-down(md){
    padding-top: 5rem;
  }
}

.person-cta__main-title {
  font-size: px-to-rem(24);
  font-weight: $font-weight-bold;
}

.person-cta__subtitle {
  font-size: px-to-rem(20);
  font-weight: $font-weight-bold;
  padding-top: min(px-to-rem(30), 20%);
}

.person-cta__function-title {
  font-size: px-to-rem(17);
}

.person-cta__inner {
  padding-left: px-to-rem(22);
}

.person-cta__buttons {
  display: flex;
  gap: spacer(3);
  padding-top: min(px-to-rem(40), 20%);
  flex-wrap: wrap;

  & > * {
    flex-shrink: 0;
  }
}

.person-cta--image {
  clip-path: polygon(0 0, 100% 0%, 100% 83%, 63% 99%, 0 88%);
  position: relative;
  margin-bottom: -3rem;

  & > * {
    max-height: 300px;

    @include media-breakpoint-up(md){
      aspect-ratio: 9 / 10;
      max-height: unset;
    }
  }

  @include media-breakpoint-up(md){
    max-width: 272px;
    height: calc(100% + 2.5rem);
    margin-top: -1rem;
    margin-bottom: unset;
    left: -2px;
  }
}
;@import "sass-embedded-legacy-load-done:435";