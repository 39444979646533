@use "sass:color";
@use "sass:map";

$darken-amount: 10%;
$button-transition-duration: $global-duration * 1.2;

.btn {
    display: inline-flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    border-radius: $border-radius;
    background: initial;
    line-height: 1.3;
    padding: spacer(4) spacer(7);
    padding-bottom: px-to-rem(17);
    text-decoration: none;
    color: $white;
    width: auto;
    gap: spacer(3); // min amount of space between text and icon
    z-index: 1;
    font-size: px-to-rem(14);
    font-weight: 600;
    border: none;
    font-family: $headings-font-family;

    &:hover {
        text-decoration: none;
        color: $white;
    }
}

// Button colors
@each $name, $map in $btn-colors {
    .btn--#{$name}{
        background: map.get($map, "background");
        transition: background-color $button-transition-duration, color $button-transition-duration;
        color: map.get($map, "color");

        &:hover {
            background-color: color.adjust(map.get($map, "background"), $lightness: -10%);
            color: map.get($map, "color");
        }

       // Button colors inverted
        &-outline {
            color: map.get($map, "color");
            background: transparent;
            box-shadow: inset 0 0 0 2px map.get($map, "color");
            transition: background-color $button-transition-duration, color $button-transition-duration;

            &:after {
                width: 0;
            }

            &:hover {
                background-color: map.get($map, "color");
            }
        }
    }
}

.btn--ghost {
    color: $color-base;
    background: $black;
    display: inline;

    &:before {
        content: '';
        position: absolute;
        inset: -4px;
        background: transparent;
        border: 4px solid black; /* Border size and color */
        clip-path: polygon(
            var(--btn-raise) 0%,
            calc(100% - var(--btn-raise)) 0%,
            100% 50%,
            calc(100% - var(--btn-raise)) 100%,
            var(--btn-raise) 100%,
            0 50%
        ); /* Matching the main shape */

        box-sizing: border-box;
        pointer-events: none; /* Ensures the pseudo-element doesn't affect clicks */
    }

    &:hover {
        background-color: color.adjust($color-base, $lightness: 10%);
        transition: background-color $button-transition-duration;
    }
}

.btn-group {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: spacer(4);
    justify-content: flex-start;
}

// Used to show icon first, button text second
.btn--reversed {
    flex-direction: row-reverse;
}

.btn--card {
    color: $color-base;
    padding: 0;
    
    &:hover {
        color: color.adjust($color-base, $lightness: 10%);
    }
}

.btn--rounded {
    border-radius: 50rem;
    font-weight: bold;
    padding: spacer(3) spacer(6);
    text-align: center;

    &:after {
        width: 0;
    }
}

.btn--square {
    --btn-raise: 7px;
    
    border-radius: 11px;
    clip-path: polygon(var(--btn-raise) 0%, calc(100% - var(--btn-raise)) 0%, 100% 50%, calc(100% - var(--btn-raise)) 100%, var(--btn-raise) 100%, 0 50%);
}

.btn--hexagon {
    position: initial;
    padding: 1rem;
    flex-shrink: 0;
    flex-grow: 0;
    clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);

    &--small {
        padding: px-to-rem(7) px-to-rem(5);
    }

    &--medium {
        padding: px-to-rem(17) px-to-rem(14);
    }

    &--nav {
        padding: px-to-rem(25) px-to-rem(20);
    }

    &--large {
        padding: px-to-rem(30) px-to-rem(25);

        @include media-breakpoint-down(lg){
            padding: px-to-rem(20) px-to-rem(15);
        }
    }

    &--xlarge {
        width: 56px;
    }

    &--xxlarge {
        width: 64px;
    }
}

.btn-hexagon-outline {
    clip-path: unset;

    svg {
        fill: none;
    }

    polygon {
        stroke-width: 1px;
        fill: transparent !important;
        transition: opacity 300ms;
    }
    
    &:hover {
        polygon {
            fill: currentColor !important;
            opacity: 0.2;
            transition: fill 500ms ease, opacity 200ms;
        }
    }

}

.btn-hex-container {
    display: grid;
    place-items: center;

    & > * {
        grid-column: 1;
        grid-row: 1;
        padding: unset;
        height: auto;
    }

    &--small {
        width: 24px;
        padding: unset;
    }

    &--medium {
        width: 36px;
    }

    &--large {
        width: 44px;
    }

    &--xlarge {
        width: 56px;
    }

    &--xxlarge {
        width: 64px;
    }
}

/* Add this class to any button with an icon inside of it
this will take care of the icon padding when used before or after text
*/
.btn__icon {
    display: flex;
    align-items: center;
    position: relative;

    .icon:not(:first-child){
        padding-right: 0;
    }

    .icon:not(:last-child){
        padding-left: 0;
    }
}

.btn--center {
    width: fit-content;
    margin: 0 auto;
}

.btn--video {
    position: relative;

    &:after {
        content: 'play_circle';
        position: absolute;
        left: 0;
        right: 0;
        text-align: center;
        font-family: 'Material Icons', sans-serif;
        font-size: 4rem;
        top: 50%;
        transform: translateY(-50%);
    }
}

.btn--disabled {
    opacity: .6;
    pointer-events: none;
    cursor: default;
}

.btn--container {
    gap: spacer(3);
}

.btn-square {

}

.btn--topbar {
    border-radius: unset;
}

.btn--inherit {
    background-color: currentColor;
}

.btn-return {
    padding: spacer(2) spacer(4);

    & > .btn__icon .icon {
        pointer-events: none;
    }

    background-color: #FAFAFA;
    border-radius: $border-radius;
}

.btn--small {
    padding: 0.5rem;
}

.btn--xsmall {
    padding: 0.2rem;
}

.btn--footer {
    padding-top: spacer(5);
    padding-bottom: spacer(5);
    border-radius: 0;
}

;@import "sass-embedded-legacy-load-done:424";