.pagination-block {
    .pagination {
        display: flex;
        align-items: center;
        padding-left: 0;
        text-decoration: none;
        list-style-type: none;
        color: $color-base;
        font-weight: $font-weight-bold;
        justify-content: center;

        .prev,
        .next {
            display: flex;
            align-items: center;
            justify-content: center;

            .material-icons {
                font-size: px-to-rem(18);
            }
        }

        a {
            color: $color-base;
            text-decoration: none;

            &:hover {
                color: $primary;
            }
        }

        li {
            &:before {
                display: none;
            }

            margin: 0 spacer(2);

            @include media-breakpoint-up(lg) {
                &:first-of-type {
                    margin-left: px-to-rem(120) !important;
                }

                &:last-of-type {
                    margin-right: px-to-rem(120) !important;
                }
            }

            &.prev {
                margin-right: auto
            }

            &.next {
                margin-left: auto;
            }

            &.current {
                color: $secondary;
                padding: 0 spacer(3);
                border-radius: $border-radius-pill;
                aspect-ratio: 1 / 1;
                display: flex;
                justify-content: center;
                align-items: center;
            }


            &.btn {
                color: $secondary;

                a {
                    color: $secondary;
                    text-decoration: none;
                }

                &.disabled {
                    opacity: .6;

                    &:before {
                        display: none;
                    }

                    &:hover {
                        transition: none;
                    }
                }
            }
        }
    }
}

;@import "sass-embedded-legacy-load-done:459";