.menu--404 {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
    display: flex;
    flex-direction: column;

    .menu__item--404 {
        padding: spacer(2) 0;
    }

    .menu__link--404 {
        text-decoration: none;
        color: rgba($black, 0.5);

        &:hover {
            color: rgba($black, 1);
        }
    }
}
;@import "sass-embedded-legacy-load-done:450";