.notification {
    position: relative;
    visibility: hidden;
    transform: translateY(-100%);
    background: $primary;
    padding: spacer(4);
    transition: transform 450ms ease-in;
}

.notification__close {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: spacer(2);
    color: $secondary;
    transition: color 350ms ease-in;

    &:hover {
        cursor: pointer;
        color: $white;
    }
}

// State active
.notification--active {
    visibility: visible;
    transform: translateY(0);
}

// State destroy
.notification--destroy {
    display: none;
}
;@import "sass-embedded-legacy-load-done:1850";