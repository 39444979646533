img {
    @extend .img-fluid;

    &.aligncenter {
        display: block;
        margin: 0 auto;
    }

    &.alignright {
        display: block;
        margin-left: auto;
    }

    &.alignleft {
        display: block;
        margin-right: auto;
    }
}

picture {
    display: flex;
} 

;@import "sass-embedded-legacy-load-done:434";