.tag {
    position: relative;
    width: fit-content;
    padding: spacer(2) spacer(4);
    font-size: px-to-rem(14);
    color: #0B0A07;
    display: flex;
    gap: spacer(3);
    align-items: center;
    z-index: 1;

    &:after {
        content: '';
        position: absolute;
        background-color: $white;
        inset: 0;
        transform: perspective(90px) rotateX(-20deg);
        border-bottom-left-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
        z-index: -1;
    }

  &--story, &--team {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
    font-size: px-to-rem(13);
  }
}

;@import "sass-embedded-legacy-load-done:1820";