.quicklink {
    display: flex;
    background: $white;
    border-radius: $border-radius;
    box-shadow: $box-shadow;
    transition: box-shadow $global-duration;
    cursor: pointer;
    flex-wrap: wrap;

    &__icon {
        transition: transform $global-duration;
    }

    &:hover {
        box-shadow: $box-shadow-hover;

        .quicklink__icon {
            transform: translateX(0.5rem);
        }
    }
}

.quicklink-divider {
    width: 1px;
    background: $secondary;
    opacity: 0.2;
}

.quicklink--archive {
    margin: spacer(4);

    @include media-breakpoint-down(lg){
        right: 0;
    }

    @include media-breakpoint-up(md){
        width: calc(50% - #{spacer(4) * 2});
    }

    @include media-breakpoint-up(lg){
        width: calc(25% - #{spacer(4) * 2});
    }
}

.quicklink--grey {
    background: $secondary;
}

.quicklink_link {
    flex: 1;
}

.quicklink__title, .quicklink__content  {
    font-size: px-to-rem(16);
    color: $color-base;
}

.quicklink__title {
    margin-bottom: 0;
}

.quicklink__content {
    margin-bottom: 0;
    font-size: px-to-rem(14);
}

.quicklink-container {
    position: relative;
    padding: spacer(7) spacer(5);
    flex: 1;
    align-items: center;
    display: flex;
    flex-wrap: nowrap;

    .icon {
        flex-shrink: 0;
    }
}

;@import "sass-embedded-legacy-load-done:1831";