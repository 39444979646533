.search {
    position: relative;
}

.searchbar__container {
    position: relative;
    display: flex;
    align-items: stretch;

    @include media-breakpoint-up(lg){
        max-width: 300px;
    }
}

.searchbar__icon {
    position: absolute;
    top: 1px;
    bottom: 1px;
    right: 1px;
    padding: spacer(4) !important;
    display: flex;
    align-items: center;
    justify-content: center;
    background: unset;
    background: rgba($black, 0.01);
    outline: unset;
    border: unset;
    transition: background-color $global-duration;

    &:hover {
        background-color: rgba($black, 0.07);
    }
}

.searchbar {
    flex-basis: 300px;
    flex-shrink: 1;
    
    .searchbar__container {
        .searchbar__field {
                padding: spacer(4);
                padding-right: 60px !important;
                height: 52px;
                min-width: 140px;
                border: 1px solid rgba($black, 0.08);
                border-radius: $border-radius;
                outline: none;
                transition: all $global-duration;
                color: rgba(#0B0A07, 0.40) !important;
                background-color: rgba(#F7F7F7, 0.28) !important;
                overflow: hidden;
                flex: 1;
                font-size: px-to-rem(13) !important;
                font-family: $font-family-base !important;
                font-weight: $font-weight-normal !important;

                &:focus, &:active, &:focus-within {
                    border-color: rgba($black, 0.1) !important;
                }
        }
    }
}

;@import "sass-embedded-legacy-load-done:1825";