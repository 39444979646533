/* stylelint-disable */

[data-bs-theme="theming_1"],
[data-bs-theme="theming_2"],
[data-bs-theme="theming_3"],
[data-bs-theme="theming_4"] {
$theme-color: var(--pe-theme);

  [data-theme-bg-color] {
    background-color: var(--pe-theme);
  }

  [data-theme-color] {
    color: var(--pe-theme);
  }

  input[type=radio]:checked   {
    border-color: var(--pe-theme) !important;
    --pe-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-3 -3 6 6'%3e%3ccircle r='2' fill='#{url-friendly-color($theme-color)}'/%3e%3c/svg%3e");
  }


  a {
    &:not(.btn) &:not(.menu__link) {
      color: var(--pe-theme);
    }
  }
}

// Theme-specific styles
[data-bs-theme="theming_1"] {
  $theme-color: #FFDA00;
  --pe-theme: #FFDA00;
  

  input[type=radio]:checked   {
    --pe-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-3 -3 6 6'%3e%3ccircle r='2' fill='#{url-friendly-color($theme-color)}'/%3e%3c/svg%3e");
  }

  input:checked[type=checkbox] {
    --pe-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='#{url-friendly-color($theme-color)}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e") !important;
    
    &:checked {
      border-color: var(--pe-theme) !important;
    }
  }

  .footer-cta {
    color: $secondary;
  }
  
  .btn--theme {
    background-color: var(--pe-theme);
    color: $senary !important;

    &:hover {
      background-color: hex-to-hsl(#FFDA00, 0, 0%, -10%);
      color: inherit;
    }
  }

  .btn-hexagon-outline {

    &:hover {
        polygon {
            fill:  var(--pe-theme) !important;
            opacity: 1;
        }
        
    }
  }

  .btn--footer  {
    color: $secondary;
    background-color: hex-to-hsl($theme-color, 0, 0%, 8%) !important;
    padding: spacer(5) spacer(14);
  }

  .quicklinks--hero {
    .quicklink {
      &--btn--tertiary {
        background: $tertiary;
        color: $white;
        transition: background-color 300ms;

        &:hover {
          background: hex-to-hsl($tertiary, 0, 0%, -10%);
        }
  
        .btn--hexagon {
          background-color: $white;
          color: $tertiary;
        }
      }
      
      
      &--btn--quaternary {
        background: $quaternary;
        color: $white;
        transition: background-color 300ms;

        &:hover {
          background: hex-to-hsl($quaternary, 0, 0%, -10%);
        }
        
        .btn--hexagon {
          background-color: $white;
          color: $quaternary;
        }
      }
      
      &--btn--quinary {
        background: $quinary;
        color: $white;
        transition: background-color 300ms;

        &:hover {
          background: hex-to-hsl($quinary, 0, 0%, -10%);
        }

        
        .btn--hexagon {
          background-color: $white;
          color: $quinary;
        }
      }
      
    }
  }
}

[data-bs-theme="theming_2"] {
  $theme-color: #9C93C6;
  --pe-theme: #9C93C6;

  .btn-hexagon-outline {

      &:hover {
          polygon {
              fill:  var(--pe-theme) !important;
              opacity: 1;
          }
      }
        
    }

  input[type=radio]:checked   {
    --pe-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-3 -3 6 6'%3e%3ccircle r='2' fill='#{url-friendly-color($theme-color)}'/%3e%3c/svg%3e");
  }

  input:checked[type=checkbox] {
    --pe-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='#{url-friendly-color($theme-color)}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e")
  
    &:checked {
      border-color: var(--pe-theme) !important;
    }
  }

  .footer-cta {
    color: $white;
  }

  .btn--theme {
    background-color: var(--pe-theme);

    &-hover {
      background-color: hex-to-hsl(#9C93C6, 0, 0%, -10%);
      transition: all 300ms;
    }

    &:hover {
      @extend .btn--theme-hover;
    }
  }

  .quicklinks--hero {
    .quicklink {
      &:hover {
        .btn--theme {
          @extend .btn--theme-hover;
        }
      }
      
    }
  }

  .btn--footer  {
    color: $secondary;
    background-color: hex-to-hsl(#9C93C6, 0, 0%, 8%) !important;
    padding: spacer(5) spacer(14);
  }
}

[data-bs-theme="theming_3"] {
  $theme-color: #7EB2DA;
  --pe-theme: #7EB2DA;


  .btn-hexagon-outline {

    &:hover {
        polygon {
            fill:  var(--pe-theme) !important;
            opacity: 1;
        }
        
    }
  }

  input[type=radio]:checked   {
    --pe-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-3 -3 6 6'%3e%3ccircle r='2' fill='#{url-friendly-color($theme-color)}'/%3e%3c/svg%3e");
  }

  input:checked[type=checkbox] {
    --pe-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='#{url-friendly-color($theme-color)}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e")
    &:checked {
      border-color: var(--pe-theme) !important;
    }
  }

  .footer-cta {
    color: $white;
  }

  .btn--theme {
    background-color: var(--pe-theme);

    &-hover {
      background-color: hex-to-hsl(#7EB2DA, 0, 0%, -10%);
    }

    &:hover {
      @extend .btn--theme-hover;
    }
  }

  .quicklinks--hero {
    .quicklink {
      &:hover {
        .btn--theme {
          @extend .btn--theme-hover;
        }
      }
      
    }
  }

  .btn--footer  {
    color: $secondary;
    background-color: hex-to-hsl(#7EB2DA, 0, 0%, 8%) !important;
    padding: spacer(5) spacer(14);
  }
}

[data-bs-theme="theming_4"] {
  $theme-color: #83C18F;
  --pe-theme: #83C18F;


  .btn-hexagon-outline {

    &:hover {
        polygon {
            fill:  var(--pe-theme) !important;
            opacity: 1;
        }
        
    }
  }

  input[type=radio]:checked   {
    --pe-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-3 -3 6 6'%3e%3ccircle r='2' fill='#{url-friendly-color($theme-color)}'/%3e%3c/svg%3e");
  }

  input:checked[type=checkbox] {
    --pe-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='#{url-friendly-color($theme-color)}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e")
    &:checked {
      border-color: var(--pe-theme) !important;
    }
  }

  .footer-cta {
    color: $white;
  }

  .btn--theme {
    background-color: var(--pe-theme);

    &-hover {
      background-color: hex-to-hsl(#83C18F, 0, 0%, -10%);
    }

    &:hover {
      @extend .btn--theme-hover;
    }
  }

  .quicklinks--hero {
    .quicklink {
      &:hover {
        .btn--theme {
          @extend .btn--theme-hover;
        }
      }
      
    }
  }

  .btn--footer  {
    color: $white;
    background-color: hex-to-hsl(#83C18F, 0, 0%, 8%) !important;
    padding: spacer(5) spacer(14);
  }
}

;@import "sass-embedded-legacy-load-done:1815";