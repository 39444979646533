.menu--primary {
    // Menu item level 1
    & > .menu__item {
        display: flex;
        align-items: stretch;
        transition: background-color $global-duration ease-in;
        padding: 0  spacer(4);

        &:hover {
            @include media-breakpoint-up(lg){
                background-color: #fafafa;
            }
        }
    }

    .menu__link {
        display: flex;
        align-items: center;
        height: 100%;
        color: $color-base;
        padding: spacer(4) 0;

        @include media-breakpoint-up(lg){
            padding-top: 0;
            padding-bottom: 0;
        }
    }
}
;@import "sass-embedded-legacy-load-done:452";