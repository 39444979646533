/****** Pixel express variables and functions ******/
@import "../../methodes/functions/functions.scss";
@import "../../variables/bootstrap.scss";
@import "../../methodes/placeholders/placeholders.scss";
@import "../../methodes/mixins/mixins.scss";

/****** Include remainder of required Bootstrap stylesheets ******/

@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/variables-dark";
@import "node_modules/bootstrap/scss/maps";
@import "node_modules/bootstrap/scss/mixins";

/****** Theming/modes ******/
@import "dark-mode";

/******  Bootstrap components ******/
@import "node_modules/bootstrap/scss/root";
@import "node_modules/bootstrap/scss/reboot";
@import "node_modules/bootstrap/scss/type";
@import "node_modules/bootstrap/scss/images";
@import "node_modules/bootstrap/scss/containers";
@import "node_modules/bootstrap/scss/grid";
@import "node_modules/bootstrap/scss/utilities";
@import "node_modules/bootstrap/scss/helpers";
@import "node_modules/bootstrap/scss/forms";

/****** Pixel express utilities ******/
@import "../../methodes/utilities/utilities.scss";
@import "../../methodes/utilities/helpers.scss";

/****** Utilities ******/
@import "node_modules/bootstrap/scss/utilities/api";

/****** Helpers ******/
@import "node_modules/bootstrap/scss/helpers/stretched-link";

;@import "sass-embedded-legacy-load-done:349";