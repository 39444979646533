.quicklink__item {
  display: flex;
  justify-content: space-between;
  flex: 1;
  padding: spacer(5) spacer(6);
  align-items: center;
  gap: spacer(6);
}

.quicklink {
  position: relative;
  min-height: 100%;
  font-family: $headings-font-family;
  font-size: px-to-rem(14);
  box-shadow: unset;
  color: $color-base;

  &--large {
    .quicklink__item {
      padding: spacer(10);
      gap: spacer(10);
    }

  }

  &:hover {
    box-shadow: unset;
  }
}



.quicklink__text {
  @extend .editor;

  strong {
    display: inline-block;
  }
  
  & > * {
    margin-bottom: unset;
  }
}

.quicklinks--hero {
  @each $breakpoint, $pane-spacer in $pane-spacers {
    @include media-breakpoint-up($breakpoint) {
        margin-top: calc(-#{$pane-spacer});
    }

    @include media-breakpoint-down(md) {
      margin-top: calc(-#{$pane-spacer} * 1.1);
    }

  }
}

.quicklink--project {
  position: unset;

  .quicklink__text {
    font-size: px-to-rem(18);
    font-family: $headings-font-family;
    font-weight: $font-weight-bold;
    line-height: 1.1;
    word-break: break-word;
  }
}

;@import "sass-embedded-legacy-load-done:1853";