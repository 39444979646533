.tabs {
    @extend .list-unstyled;

    display: flex;
    align-items: center;
    margin-bottom: spacer(8);

    @include media-breakpoint-up(lg){
        flex-direction: column;
        align-items: stretch;
        justify-content: stretch;
        margin-left: 0;
        margin-bottom: 0;
    }
}

.tab {
    position: relative;
    padding: spacer(4);
    overflow: hidden;
    cursor: pointer;
    border: 1px solid transparent;
    transition: border-top .3s, border-left .3s, border-right .3s;

    @include media-breakpoint-up(lg) {
        transition: border-top .3s, border-left .3s, border-bottom .3s;
    }

    &.is-active {
        border-color: rgba($black, .1);
        border-bottom-color: $white;
        margin-bottom: -2px;
        z-index: 9;

        @include media-breakpoint-up(lg) {
            border-color: rgba($black, .1);
            border-right-color: $white;
            margin-right: -1px;
            margin-bottom: 0;
        }
    }
}

@each $name, $color in $theme-colors {
    .bg--#{$name} {
        .tab.is-active {
            border-bottom-color: $color;
            margin-bottom: -1px;

            @include media-breakpoint-up(lg) {
                border-right-color: $color;
                margin-right: -1px;
            }
        }
    }
}

.tabs__container {
    border: 1px solid rgba($black, .1);
    margin-top: -#{spacer(8)};
    
    @include media-breakpoint-up(lg) {
        margin-left: -#{spacer(10)};
        margin-top: 0;
        height: 100%;
    }
}

.tab__content {
    transform: translateY(10px);
    height: 0;
    opacity: 0;
    overflow: hidden;
    transition: opacity 250ms ease-in, transform 250ms ease-in;

    @include media-breakpoint-up(lg){
        display: flex;
        gap: spacer(4);
        flex-wrap: wrap;
    
        & > * {
            flex: 1;
            flex-basis: calc(50% - #{spacer(4)});

            &:nth-child(3n+3){
                flex-grow: 0;
            }
        }
    }

    &.is-active {
        height: auto;
        opacity: 1;
        transform: translateY(0);
    }
}

.tab__block {
    padding: spacer(6);
}

;@import "sass-embedded-legacy-load-done:1849";