.card--team {
    height: 360px;
    flex-direction: column-reverse;
    padding: spacer(3);

    .card__header {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;    
    
        .card__image {
            width: 100%;
            height: 100%;
            object-position: top;
        }
    }

    .card__body {
        z-index: 1;
        flex: unset;
        background: $white;
        padding: spacer(4);
        border-radius: $border-radius-sm;
        text-align: center;
        font-size: px-to-rem(14);
        color: $color-base;
        font-family: $headings-font-family;
        
        .card__title, p {
            margin-bottom: 0;
        }
    }
}


;@import "sass-embedded-legacy-load-done:443";