.pane {
    display: block;
    position: relative;

    @each $breakpoint, $pane-spacer in $pane-spacers {
        @include media-breakpoint-up($breakpoint) {
            padding: $pane-spacer 0;
        }
    }

    &.bg--default {
        &+ .pane.bg--default {
            padding-top: 0;
        }
    }

    &.bg--none {
        &:not(.pane--single-image) {
            &+ .pane.bg--none {
                padding-top: 0;
            }
        }
    }
};

@each $name, $color in $theme-colors {
    .pane.bg--#{$name} {
        + .pane.bg--#{$name} {
            padding-top: 0;
        }
    }
}

.pane--single-image {
    position: relative;
    z-index: 1;

    @each $breakpoint, $pane-spacer in $pane-spacers {
        @include media-breakpoint-up($breakpoint) {
            margin-top: calc(-#{$pane-spacer} / 2);
        }
    }

    &:after {
        content: '';
        position: absolute;
        height: 50%;
        bottom: 0;
        left: 0;
        right: 0;

        @extend .single-project;

        z-index: -1;
    }
}

.pane--archive-projects {
    .row {
        .col-lg-8 {
            .card-project {
                min-height: 514px;
                max-height: 514px;
            }
        }
        
        .col-lg-4 {
            &:nth-child(4n + 2) {
                .card-project {
                    min-height: 455px;
                }
            }

            &:nth-child(4n + 3) {
                .card-project {
                    min-height: 495px;
                }
            }

            &:nth-child(4n + 4) {
                .card-project {
                    min-height: 514px;
                }
            }
        }
    }
}

.bg--white {
    .bg--none {
        +.tcmp-triangle {
            color: $white;
        }
    }
}

;@import "sass-embedded-legacy-load-done:421";