input[type="text"],
input[type="password"],
input[type="email"],
input[type="tel"],
input[type="image"],
input[type="date"],
input[type="month"],
input[type="number"],
input[type="week"],
input[type="search"],
input[type="file"],
input[type="url"],
textarea {
    @extend .form-control;
}

input[type="file"] {
    background-color: transparent !important;
}

.form-control {
    font-size: $font-size-base !important;
    padding: spacer(3) spacer(6) !important;
    border-color: rgba($quinary, 0.1) !important;
    color: #202020 !important;
    background-color: #F7F7F7 !important;

    &:focus {
        border-color: $quinary !important;
        box-shadow: unset;
    }

    &::placeholder {
        color: rgba(#202020, 0.5)!important;
        font-family: $font-family-base !important;
    }
}

;@import "sass-embedded-legacy-load-done:1857";