// Default swiper
.swiper {
    &__slide {
        @extend .swiper-slide;
    }

    &__buttons {
        position: relative;
        display: flex;
        align-items: center;
        gap: spacer(2);

        &--absolute {
            position: absolute;
            justify-content: space-between;
            top: 50%;
            transform: translateY(-50%);
            z-index: 10;
            right: 0;
            left: 0;
            padding: 0 spacer(8);
        }
        
        .swiper__button {
            display: flex;
            background-color: $white;
            color: $quinary;
            position: relative;
            transform: unset;
            left: unset;
            top: unset;
            right: unset;
            bottom: unset;
            padding: spacer(4);
        }
    }

    /* stylelint-disable */
    &__button {
        display: flex;
        background-color: $white;
        color: $quinary;
        border-radius: $border-radius-pill;
        position: relative;
        transform: unset;
        left: unset;
        top: unset;
        right: unset;
        bottom: unset;
        padding: spacer(4);
        border: 1px solid $quinary;

        &--absolute {
            position: absolute;
            justify-content: space-between;
            align-items: center;
        }

        &:has( > .btn--hexagon) {
            background: unset;
            border: unset;
            width: auto;
        }
        

        &:hover {
        }

        &--next {
            right: spacer(3);
            transform: translateY(-50%);
        }

        &--prev {
            left: spacer(3);
            transform: translateY(-50%);
        }

        &--disabled {
            opacity: .75;
            pointer-events: none;
        }
    }
    /* stylelint-enable */


    .slide__text {
        position: absolute;
        inset: 0;
        left: 5vw;
        right: 5vw;
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    @each $name, $breakpoint in $grid-breakpoints {
        &.swiper--destroy-#{$name} {
            @include media-breakpoint-up($name) {
                margin: 0 -20px;

                .swiper__slide {
                    margin: 0 !important;
                    padding: 20px;
                    width: 25% !important;

                    &.swiper-slide-duplicate {
                        display: none;
                    }
                }

                .swiper-wrapper {
                    transform: none !important;
                    flex-wrap: wrap;
                    height: unset !important;
                }
            }
        }
    }

    &--image_text {
        .slide__text {
            position: relative;
            left: 0;
            right: 0;
            padding: 5vw;

            @include media-breakpoint-up(lg) {
                position: absolute;
                left: 5vw;
                right: 5vw;
                padding: 0;
            }
        }

        .swiper__slide {
            .img-fluid {
                @include media-breakpoint-down(lg) {
                    position: absolute;
                    inset: 0;
                    height: 100%;
                    object-fit: cover;
                    min-height: 70vh;
                }
            }
        }
    }

    &--timeline-thumbs { 
        overflow: visible !important;

        .timeline__text {
            width: fit-content;
        }

        .swiper__slide {
            min-width: 25%;
            width: auto !important;
            font-family: $headings-font-family;
            font-weight: $headings-font-weight;
            color: $headings-color;

            &.swiper-slide-thumb-active {
                font-weight: bold;
            }

            &:not(.swiper-slide-thumb-active) {
                .timeline-indicator {
                    .btn-hex-container {
                        polygon {
                            transition: fill 300ms;
                            fill: transparent;
                        }
                    }
                }
            }

            display: flex;
            flex-direction: column;

            &:not(&:last-child){
                &:before {
                    position: absolute;
                    content: "";
                    top: px-to-rem(14);
                    left: 23px;
                    transform: translateY(-50%);
                    height: 1px;
                    background: #fff;
                    z-index: 1;
                    width: calc(100% - 4px);
                }
            }
        }
    }

    &--timeline {
        overflow: visible !important;

        .swiper__slide {
            height: auto;
            display: flex;
            flex-direction: column;

            @include media-breakpoint-up(lg) {
                flex-direction: row;
            }

            .slide__title {
                color: $secondary;
                font-family: $headings-font-family;
                font-weight: $headings-font-weight;
            }

            .image-timeline {
                clip-path: polygon(0 0, 100% 0, 100% 90%, 95% 100%, 25% 100%, 0 100%);

                img {
                    width: 100%;
                }
                
                @include media-breakpoint-up(lg) {
                    object-fit: cover;
                    min-width: calc(100% / 3);
                    width: calc(100% / 3);

                    img {
                        width: 100%;
                        height: auto; 
                    }
                }
            }

            .slide__text {
                position: relative;
                background: $white;
                display: flex;
                flex-wrap: wrap;
                left: unset;
                right: unset;
                z-index: 1;
                padding: spacer(6) spacer(7);

                &:after {
                    content: "";
                    height: 120px;
                    width: calc(100% + 1px);
                    background: inherit;
                    position: absolute;
                    top: -1px;
                    bottom: -1px;
                    left: 50%;
                    transform: translateY(-50%) translateX(-50%);
                    clip-path: polygon(0 0, 100% 50%, 100% 100%, 0% 100%);
                    z-index: -1;
                }
                
                @include media-breakpoint-up(lg) {
                    &:after {
                        content: "";
                        height: calc(100% + 1px);
                        width: 120px;
                        background: inherit;
                        position: absolute;
                        top: -1px;
                        bottom: -1px;
                        left: 0%;
                        transform: translateX(-50%);
                        clip-path: polygon(0 0, 100% 0, 100% 100%, 50% 100%);
                        z-index: -1;
                    }
                }
            }

            &:nth-child(even) {
                img {
                    clip-path: polygon(0 0, 95% 0, 100% 10%, 100% 100%, 0 100%);
                }

                .slide__text {
                    @include media-breakpoint-up(lg) {
                        &:after {
                            clip-path: polygon(50% 0, 100% 0, 100% 100%, 0% 100%);
                        }
                    }
                }
            }
        }
    }
}

// Used for cards/posts carousel
.swiper-container {
    margin-left: - #{spacer(4)};
    margin-right: - #{spacer(4)};
}

.swiper__button--disabled {
    display: none !important;
}

.swiper--logo {
    overflow: visible !important;

    .swiper-wrapper {
        transition-timing-function: linear;
    }

}

.pane--logo_slider {
    position: relative;
    overflow: hidden;
    margin-left: - $container-padding-x / 2;
    margin-right: - $container-padding-x / 2;
}

.swiper--image {
    @include media-breakpoint-up(lg){
    .swiper__slide  {
        img, & > * {
                width: 100%;
                aspect-ratio: 12 / 5;
                object-fit: cover;
            }
        }
    }
}

;@import "sass-embedded-legacy-load-done:444";