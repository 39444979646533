
.card--location { 
    .card__title {
        text-align: center;
        font-size: $h5-font-size;
    }

    .card__body {
        padding: spacer(8);
    }

    .card__footer {
        padding-top: 0;
        text-align: center;
    }

    .card__content {
        font-family: $headings-font-family;
        text-align: center;
    }
}

;@import "sass-embedded-legacy-load-done:1833";