.vacancy-details {
    list-style-type: none;
    padding-left: 0;
    display: flex;
    flex-direction: column;
    gap: spacer(3);

    &__item {
        display: flex;
    }
}

.card--vacancy {
    background: transparent;

    .card__image {
        height: 150px;
        clip-path: polygon(0 0, 100% 0, 100% 90%, 95% 100%, 25% 100%, 0 100%);
    }


    .card__title {
        z-index: 1;
        font-size: $h5-font-size;
    }

    .card__body {
        background: $white;
        padding: spacer(4) spacer(8);    
        
        @include media-breakpoint-up(lg){
            padding: spacer(8);
        }
        
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;

        > * {
            position: relative;
            z-index: 1;
        }

        &:after {
            content: "";
            height: 100px;
            width: 100%;
            background: $white;
            position: absolute;
            top: -1px;
            bottom: -1px;
            left: -1px;
            transform: translateY(-50%);
            clip-path: polygon(0 0, 100% 50%, 100% 100%, 0% 100%);
            z-index: 0;
        }

    }
    
    .card__specs {
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: $secondary;
        font-size: px-to-rem(14);
        gap: spacer(3);
        font-weight: $font-weight-normal;
        
        .specification {
            display: flex;
            gap: spacer(3);
            align-items: center;
            font-family: $headings-font-family;
        }

        i {
            width: spacer(4);
         }
    }
        
    .card__footer {
        display: flex;
        flex-direction: column; 
        background: $white;

        .btn-hexagon-outline {
            margin-top: spacer(10);
        }
    }

    .card__content {
        font-family: $headings-font-family;
        flex: unset;
    }
 
    
    @include media-breakpoint-up(lg) {
        flex-direction: row; 
        
        .card__image {
            height: auto;
        }
    
        .card__header {
            max-width: 25%;
        }

        .card__footer {
            flex-direction: row;
            width: 25%;
            padding-left: 0;
            padding-right: spacer(10);
            justify-content: space-between;
            align-items: center;

            .btn-hexagon-outline {
                margin-top: 0;
            }
        }

        .card__body {
            &:after {
                content: "";
                height: 100%;
                width: 100px;
                background: $white;
                position: absolute;
                top: 0;
                bottom: -1px;
                left: 0%;
                transform: translateX(-50%);
                clip-path: polygon(50% 0, 100% 0, 100% 100%, 0% 100%);
                z-index: 0;
            }
        }
    }
}

;@import "sass-embedded-legacy-load-done:442";